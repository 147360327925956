<template>
  <div ref="mapContainer" id="mapContainer"></div>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
  name: "LeafletMap",
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    this.map = L.map("mapContainer").setView([48.60538, 17.41993], 15);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);
    var customPane = this.map.createPane("customPane");
    customPane.style.zIndex = 399;
    var marker = L.icon({
      iconUrl: require("../assets/icon.png"),
      iconSize: [50, 50],
      iconAnchor: [25, 50],
      popupAnchor: [0, -50],
    });
    L.marker([48.60538, 17.41993], { icon: marker }).addTo(this.map);
  },
  onBeforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
  },
};
</script>

<style scoped>
#mapContainer {
  width: 100%;
  height: 400px;
  border-top: 3px solid #000000;
  border-bottom: 3px solid #000000;
}
</style>